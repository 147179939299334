<template>
  <div class="sendFactory">
    <el-button
      type="primary"
      size="small"
      @click="showSendFactory"
      v-if="orderStatus == 41"
      >发到工厂</el-button
    >
    <el-dialog
      title="选择供应商"
      :visible.sync="showSendFactoryVisible"
      width="30%"
    >
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
      ></avue-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSendFactoryVisible = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="sendFactory">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { sendFactory } from "@/api/order/orderListData";
let formOption = {
  menuBtn: false,
  size: 'medium',
  column: [
    {
      prop: 'supplierId',
      labelWidth: 0,
      span: 24,
      type: 'select',
      rules: [
        {
          required: true,
          message: '供应商必填'
        }
      ]
    }
  ]
}

export default {
  props: {
    orderStatus: {
      type: String,
      required: true,
    },
    factoryData: {
      type: Array,
      required: true,
    },
    sup_this: {
      type: Object,
      required: true,
    },
    beforeSubmit: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    this.$store.dispatch('HandleOption', formOption)
    return {
      showSendFactoryVisible: false,
      supplierOptions: [],
      form: {},
      formOption,
      supplier: "",
      loading: false
    };
  },
  methods: {
    async showSendFactory() {
      if (!this.factoryData.length) {
        this.$message.warning("请先选择数据再进行此操作");
        return false;
      }
      this.showSendFactoryVisible = true;
      this.supplierOptions = await this.$store.dispatch('GetDic', 'supplierId');
    },
    async sendFactory() {
      let valid = await awaitFormResolve(this.$refs.form.validate())
      if (valid) {
        this.loading = true
        let valid = await this.beforeSubmit()
        if (!valid) return
        sendFactory({ idList: this.factoryData, ...this.form})
          .then(({code, message}) => {
            if ($SUC({ code })) {
              if (message) {
                this.$message.warning(message)
              } else {
                this.$message.success('发布成功')
              }
              this.sup_this.init()
            } else {
              this.$message.success('发布失败')
            }
            this.showSendFactoryVisible = false
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
